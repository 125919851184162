import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function PhoneCard() {
  return (
    <Card>
      <a aria-label="Call Phone Number +1 (217) 536-5434" href="tel:+1 (217) 536-5434" className="none-link" target="_blank" ><CardActionArea className="color-scheme" >
        <CardMedia
          component="img"
          height="140"
          image="/static/callusnow.png"
          width="100%"
          alt="Contact Us Now"
          loading="lazy"
          title="Danny's Bar & Grill Contact Us Now"
        />
        <CardContent>
          <Typography gutterBottom variant="h4" component="div">
            Contact Us Now
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            Phone: +1 (217) 536-5434
              <br></br>
            Email: mckln60@yahoo.com
          </Typography>
        </CardContent>
      </CardActionArea></a>
    </Card>
  );
}