import ReactPlayer from "react-player";
import "../../../App.css";
import {
  Container,
  Card,
  Row,
  Col,
  h5,
  p,
  h2,
  h3,  
  div,
  Alert,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typography from "@mui/material/Typography";

export const OrderSlider = () => {
  return (
    <div className="OrderSlider">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Order Now: Browse, view, and track your order.
          </title>
          <link rel="canonical" href="/menu" />
          <meta
            name="description"
            content="If you don't have time to enjoy a meal with us, don't sweat it. Place your order for carry-out for a chance to enjoy some good eats in the comfort of your own home. "
          />
        </Helmet>
      </HelmetProvider>
      <div className="OrderHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col sm={12} lg={8}>
              <h1 className="left gold">Now, that's a lineup.</h1>
              <Typography variant="body1" className="white">
                {" "}
                Let us handle dinner tonight! After all of your hard work these days, you definitely deserve it.
              </Typography>
              <h2 className="left gold whitespace">
                Things are heating up in our kitchen!
              </h2>
              <Typography variant="body1" className="white">
                {" "}
                Get something fresh off the grill this evening from us. Yes, that means putting those pots and pans up.
              </Typography>
            </Col>
            <Col sm={0} lg={4}>
              {/*<Card>
        <Card.Img src="/static/1.jpg" />
    </Card> */}
            </Col>
            <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
    </div>
  );
};