import "../../../App.css";
import {
  Container,
  Row,
  Col,
  Button,
  h5,
  h1,
  h6,
  p,
  h2,
  div,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player";
// import center from '../../../assets/css/custom.css';
import { MenuNavigation } from "../components/MenuNavigation";
import { Footer } from "../components/Footer";
import aboutimg1 from "../../../assets/images/about-img-01.jpg";
import aboutimg2 from "../../../assets/images/about-img-02.jpg";
import aboutimg3 from "../../../assets/images/about-img-03.jpg";
import LovingAllPeople from "../../../assets/images/loving-all-people-icon.png";
import CreatingSuperheroes from "../../../assets/images/creating-superheroes-icon.png";
import ExplodingTheBox from "../../../assets/images/exploding-the-box-icon.png";
import ImpactingTheWorld from "../../../assets/images/impacting-the-world-icon.png";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typography from "@mui/material/Typography";

export const About = () => {
  useEffect(() => {
    document.title = "About Us: Danny's Bar & Grill has it all! Come join us for the ultimate experience in flavor and hospitality.";
    // ReactGA.initialize("UA-172332025-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="About">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>About Us: Danny's Bar & Grill has it all! Come join us for the ultimate experience in flavor and hospitality.</title>
          <link rel="canonical" href="/about" />
          <meta
            name="description"
            content="Come join us at Danny's Bar & Grill and take part in our awesome activities like darts and pool! Our service-oriented staff will be sure to make your experience enjoyable."
          />
        </Helmet>
      </HelmetProvider>
      <MenuNavigation />
      <div className="HeaderImg about-info">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={8}>
              <Typography variant="body1" className="white">
              This is comfort food at its finest{" "}
              </Typography>
              <br />
              <h1 className="gold">
                Come visit us today from
                <br></br>
                7 AM to 1 AM
              </h1>
            </Col>
            <Col xs={8}>
              <Typography variant="body1" className="white">
                {" "}
                We're always serving up something delicious! Take a break from the kitchen tonight by stopping by our place.
              </Typography>
              <br />
              <Button
                className="btn-red margin-top-10 margin-bottom-10"
                href="/menu"
              >
                Order Now{" "}
              </Button>
            </Col>
            <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <div className="col-lg-4">
            <div class="about-info-left">
              <h2>Highlights from the Business</h2>
              <ul className="paddingSpecial">
                <li>Bar games</li>
                <li>Fast service</li>
                <li>Karaoke</li>
                <li>Live music</li>
                <li>Live performances</li>
                <li>Sports</li>                
              </ul>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="about-info-right">
              <Typography className="title" variant="h4">
                Our doors opened over two decades ago
              </Typography>
              <Typography variant="boby1">
                {" "}
                
                Experience the vibrant community we've cultivated here in Effingham, where every visit is a memorable occasion. Indulge in mouthwatering dinners and embrace the excitement of our Friday fun-filled events, making us the ultimate destination for unforgettable experiences. Since 2000, we have proudly served as the go-to bar for exceptional food and endless entertainment. Immerse yourself in our lively atmosphere, complemented by our warm and attentive staff, ensuring that your time with us is truly unparalleled. Celebrating 23 years of excellence, we continue to be the preferred spot in Effingham for the perfect drink. Unleash your inner adventurer and discover an evening like no other, engaging in thrilling games and enjoying the exceptional service provided by our welcoming team. Join us and elevate your social experiences to new heights.{" "}
              </Typography>
              <br />
            </div>
            <div className="inner-about-gallery margin-top-30 ">
              <div className="col-lg-12">
                <div className="col-lg-3 col-md-3 float-left">
                  <img
                    width="100%"
                    height="100%"
                    title="Danny's Bar & GrillAbout Us Image1"
                    src={aboutimg1}
                    alt="Danny's Bar & GrillAbout Us Image1"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-3 col-md-3 float-left">
                  <img
                    width="100%"
                    height="100%"
                    title="Danny's Bar & GrillAbout Us Image2"
                    src={aboutimg2}
                    alt="Danny's Bar & GrillAbout Us Image2"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-3 col-md-3 float-left">
                  <img
                    width="100%"
                    height="100%"
                    title="Danny's Bar & GrillAbout Us Image3"
                    src={aboutimg3}
                    alt="Danny's Bar & GrillAbout Us Image3"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          <col className="whitespace"></col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
export default withRouter(About);