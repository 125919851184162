/*import "../../../../../App.css";*/
import React from "react";
import "../../../../../assets/css/custom.css";
import {
  Container,
  Col,
} from "react-bootstrap";
import { FaInstagramSquare, FaFacebookF, FaTwitterSquare, FaYelp, } from "react-icons/fa";
import { ImYoutube2 } from "react-icons/im";
import Typography from '@mui/material/Typography';

export const SocialConnect = () => {
  return (
    <div className="social-connect">
      <Container className="social-icons ">
        <Col xs={12} className="social-title">
        <Typography variant="h4" gutterBottom component="div">
          Social Connect
        </Typography>
        </Col>
        <Col xs={12} className="social-icons icon">
          <ul>
            <li>
              <a href="https://www.facebook.com/dannysbarandgrilleffingham" target="_blank">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/DannysBarNGrill" target="_blank">
                <FaTwitterSquare />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/dannys_bar_grill" target="_blank">
                <FaInstagramSquare />
              </a>
            </li>
            <li>
              <a href="https://www.yelp.com/biz/dannys-bar-and-grill-effingham" target="_blank">
                <FaYelp />
              </a>
            </li>            
          </ul>
        </Col>
      </Container>
    </div>
  );
};