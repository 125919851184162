import "../../../App.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import Typography from "@material-ui/core/Typography";

export const ContactSlider = () => {
  return (
    <div className="ContactSlider">
      <div className="ContactHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={12} lg={7}>
              <Typography className="white">
                {" "}
                Service Options: Dine-in | Outdoor seating | Takeout
              </Typography>
              <h1 className="left gold blankspace">
                Good times are ahead.
                <br></br>
                Let's play!
              </h1>
              <Typography className="white blankspace">
                {" "}
                When it comes to great food and a warm welcome, Danny's Bar & Grill is the number one spot.
              </Typography>
              {/* <Typography className="white blankspace">
                {" "}
                Contact Us: 7722 Us-45, Effingham, IL 62401
              </Typography> */}
            </Col>
            {/* <Col xs={12} lg={5} className="PhonerightImg"></Col>
            <col className="whitespace"></col> */}
          </Row>
        </Container>
      </div>
    </div>
  );
};