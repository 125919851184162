import "bootstrap/dist/css/bootstrap.min.css";
import "../../../App.css";
// import whitespace from '../../../assets/css/custom.css';
import Dept1 from "../../../assets/images/order-nowicon-01.svg";
import Dept2 from "../../../assets/images/join-eloyalty-01.svg";
import Dept3 from "../../../assets/images/visit-our-website-01.svg";
import { Container, Row, Col, Button, h1, p, h6, hr } from "react-bootstrap";
import Logo from "../../../assets/images/logo.png";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import parseHtml from "html-react-parser";
import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typography from "@mui/material/Typography";
import CookieIcon from "../../../assets/images/cookie-icon.svg";
import CookieConsent from "react-cookie-consent";
import BottomSILogo from "../components/BottomSILogo";
import { Navbar, Nav } from "react-bootstrap";
import background from "../../../assets/images/background.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;

const theme = createTheme();

export const Mobile = () => {
  // useEffect(() => {
  //   document.title = "Welcome";
  // }, []);

  const history = useHistory();
  const [cookieSIC, cookieSICName, setCookieSICName, removeCookieSICName] =
    useCookies(["sic_name", "sic_user_id"]);

  const getCookie = (key) => {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
  };

  const setCookie = (key, value) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
    document.cookie =
      key + "=" + value + ";expires=" + expires.toUTCString() + ";path=/";
  };

  useEffect(() => {
    var verifyOrderProcess = new EventSource(
      "https://ecommv2.servingintel.com/postpayment/verifyorderprocess/" +
        siteId +
        "/" +
        getCookie("orderId") +
        "?server_id=" +
        serverId
    );
    verifyOrderProcess.onmessage = function (event) {
      if (event.data === 1) {
        verifyOrderProcess.close();
        setCookie("orderId", "");
      }
    };

    var sourcePWA = new EventSource(
      "https://ecommv2.servingintel.com/pwa/check_update"
    );
    sourcePWA.onmessage = function (event) {
      var u_items = JSON.parse(event.data);
      //console.log(u_items[0].utime);
      if (u_items[0].time != "") {
        if (
          sessionStorage.getItem("pwa_update_date_time") !== u_items[0].time
        ) {
          caches.delete("appV1");
          sessionStorage.setItem("pwa_update_date_time", u_items[0].time);
          window.location.reload();
        }
      }
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
    <Grid
          container
          component="main"
          sx={{ fontSize: "${newFontSize}px",
                height: "100vh",
                backgroundImage: "url(" + background + ")",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
    >
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>The phrase "dig in" was made for everything we offer.</title>
          <link rel="canonical" href="/" />
          <meta
            name="description"
            content="WSearching for delicious, local eats in Effingham? We have good news -- you'll find them here!"
          />
        </Helmet>
      </HelmetProvider>
      <Grid className="center" xs={12} style={{ padding: "0 15px", height: "35vh" }}>
      <h2 className="white title margin-top-20">
          {cookieSICName.sic_name
            ? parseHtml(
                "I knew you'd come, <b>" + cookieSICName.sic_name + "!</b>"
              )
            : "I knew you'd come, Welcome"}{" "}
          to
        </h2>
        <img
          width="100%"
          height="100%"
          title="Danny's Bar & Grill Logo"
          className="App-logo title"
          src={Logo}
          alt="Danny's Bar & Grill Logo"
          loading="lazy"
          style={{ width: "20vh" }}
        />
        <h2
          // component="h2"
          // variant="h4"
          className="white"
        >
          The phrase "dig in" was made for everything we offer.
        </h2>
        </Grid>
      <div className="dept-btn-cont center">
        <Col xs={12}>
          <a href="/menu" target="_self" className="none-link">
            <div className="col-lg-12">
              <div className="our-dept-main">
                <div className="dept-front dept-1">
                  <img
                    title="Order Now"
                    loading="lazy"
                    src={Dept1}
                    alt="Order Now"
                    width="72"
                    height="auto"
                  />
                  <Typography component="h3" variant="h3" className="white">
                    Order Now
                  </Typography>
                </div>
              </div>
            </div>
          </a>
        </Col>
        {/* <Col xs={12}>
          <div className="col-lg-12">
            <div className="our-dept-main">
              <div className="dept-front dept-2">
                <img
                  title="Join AJ's Club"
                  loading="lazy"
                  src={Dept2}
                  alt="Join AJ's Club"
                  width="72"
                  height="auto"
                />
                <Typography component="h3" variant="h3" className="white">
                  <Nav.Link
                    onClick={() => {
                      history.push(
                        cookieSIC.sic_user_id > 0 ? "/rewards" : "/register"
                      );
                    }}
                  >
                    {cookieSIC.sic_user_id > 0
                      ? "eLoyalty"
                      : "Join AJ's Club"}
                  </Nav.Link>
                </Typography>
              </div>
            </div>
          </div>
        </Col> */}
        <Col xs={12}>
          <a href="#" onClick={() => history.push("/home")} target="_self" className="none-link">
            <div className="col-lg-12">
              <div className="our-dept-main">
                <div className="dept-front dept-3">
                  <img
                    title="Visit Our Website"
                    loading="lazy"
                    src={Dept3}
                    alt="Visit Our Website"
                    width="72"
                    height="auto"
                  />
                  <Typography component="h3" variant="h3" className="white">
                    Visit Our Website
                  </Typography>
                </div>
              </div>
            </div>
          </a>
        </Col>
      </div>
      <CookieConsent>
        location="bottom"
        buttonText="Accept all cookies"
        cookieName="myAwesomeCookieName2"
        buttonClasses="btn btn-red"
        style={{ background: "#000" }}
        buttonStyle={{ fontSize: "16px" }}
        expires={365}
        </CookieConsent>
        <HelmetProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Great way to start the day.</title>
            <link rel="canonical" href="/" />
            <meta
              name="description"
              content="We are a team who seeks direction from the Holy Spirit. Food for your soul! Located in Saint Charles & opening up a new location in South Elgin soon!"
            />
          </Helmet>
        </HelmetProvider>
        <Grid
          className="center"
          xs={12}
          style={{ padding: "0 15px", height: "35vh" }}
        >
          <h2 className="white title margin-top-20">
            {cookieSICName.sic_name
              ? parseHtml(
                  "I knew you'd come, <b>" + cookieSICName.sic_name + "!</b>"
                )
              : "I knew you'd come, Welcome"}{" "}
            to
          </h2>
          <img
            width="100%"
            height="100%"
            title="AJ's Keto Factory Logo"
            className="App-logo title"
            src={Logo}
            alt="AJ's Keto Factory Logo"
            loading="lazy"
            style={{ width: "20vh" }}
          />
          <h2
            // component="h2"
            // variant="h4"
            className="white"
          >
            Great way to start the day.
          </h2>
        </Grid>
        <div className="dept-btn-cont center">
          <Col xs={12}>
            <a
              href="#"
              onClick={() => history.push("/menu")}
              target="_self"
              className="none-link"
            >
              <div className="col-lg-12">
                <div className="our-dept-main">
                  <div className="dept-front dept-1">
                    <img
                      title="Order Now"
                      loading="lazy"
                      src={Dept1}
                      alt="Order Now"
                      width="72"
                      height="auto"
                    />
                    <Typography component="h3" variant="h3" className="white">
                      Order Now
                    </Typography>
                  </div>
                </div>
              </div>
            </a>
          </Col>
          <Col xs={12}>
            <div className="col-lg-12">
              <div className="our-dept-main">
                <div className="dept-front dept-2">
                  <img
                    title="Join AJ's Club"
                    loading="lazy"
                    src={Dept2}
                    alt="Join AJ's Club"
                    width="72"
                    height="auto"
                  />
                  <Typography component="h3" variant="h3" className="white">
                    <Nav.Link
                      onClick={() => {
                        history.push(
                          cookieSIC.sic_user_id > 0 ? "/rewards" : "/register"
                        );
                      }}
                    >
                      {cookieSIC.sic_user_id > 0
                        ? "eLoyalty"
                        : "Join AJ's Club"}
                    </Nav.Link>
                  </Typography>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <a
              href="#"
              onClick={() => history.push("/home")}
              target="_self"
              className="none-link"
            >
              <div className="col-lg-12">
                <div className="our-dept-main">
                  <div className="dept-front dept-3">
                    <img
                      title="Visit Our Website"
                      loading="lazy"
                      src={Dept3}
                      alt="Visit Our Website"
                      width="72"
                      height="auto"
                    />
                    <Typography component="h3" variant="h3" className="white">
                      Visit Our Website
                    </Typography>
                  </div>
                </div>
              </div>
            </a>
          </Col>
        </div>
        <CookieConsent
          location="bottom"
          buttonText="Accept all cookies"
          cookieName="myAwesomeCookieName2"
          buttonClasses="btn btn-red"
          style={{ background: "#000" }}
          buttonStyle={{ fontSize: "16px" }}
          expires={365}
        >
          <div className="center">
            <Col xs={3} className="padding-bottom-20 center">
              <img
                width="100%"
                height="100%"
                title="Accept all cookies Icon"
                src={CookieIcon}
                size="15"
                alt="Accept all cookies Icon"
                loading="lazy"
              />
            </Col>
            <p>
              <h3 className="center"> Your Privacy </h3>
            </p>
          </div>

          <Typography>
            Greetings! Our website uses cookies so we may better serve you. By
            clicking “Accept all cookies” and by continuing to browse our site
            you are agreeing to our{" "}
          </Typography>
          <Typography>
            <a
              href="#"
              onClick={() => {
                history.push("/terms-and-conditions");
              }}
            >
              Terms and Conditions
            </a>
          </Typography>
        </CookieConsent>
        <BottomSILogo />
      </Grid>
    </ThemeProvider>
  );
};
