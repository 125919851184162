import { CarouselItem, CarouselCaption, Carousel, img } from "react-bootstrap";
import Slider1 from "../../../../../assets/images/header_img_1.jpg";
import Slider2 from "../../../../../assets/images/header_img_2.jpg";
import Slider3 from "../../../../../assets/images/header_img_3.jpg";
import Slider4 from "../../../../../assets/images/header_img_4.jpg";
import Slider5 from "../../../../../assets/images/header_img_5.jpg";
import React, { useEffect } from "react";

export const HomepageSlider = () => {
  useEffect(() => {
    document.title = "We're ready to serve up a jolly time by sharing our delicious recipes. Dash on by this week to join us for lunch or dinner.";
  }, []);

  return (
    <div>
      <Carousel fade>
        <Carousel.Item>
          <img width="100%" height="100%" title="Danny's Bar & Grill" className="d-block w-100" src={Slider1} alt="Danny's Bar & Grill" loading="lazy"/>
          <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
          <h1>Welcome to Danny's Bar & Grill</h1>
          <h5>Unique flavors make every day better. There's nothing we love more than sharing our favorite recipes with you!</h5>
          </div>
        </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img width="100%" height="100%" title="Danny's Bar & Grill" className="d-block w-100" src={Slider2} alt="Danny's Bar & Grill" loading="lazy"/>
          <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
          <h1>Welcome to Danny's Bar & Grill</h1>
          <h5>Kill some time and have some fun playing our video games this weekend. Don't forget to order your favorite drinks!</h5>
          </div>
        </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img width="100%" height="100%" title="Danny's Bar & Grill" className="d-block w-100" src={Slider3} alt="Danny's Bar & Grill" loading="lazy"/>
          <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
          <h1>Welcome to Danny's Bar & Grill</h1>
          <h5>This is exactly what you want for dinner. Start your week the right way with some of your favorite meats here with us!</h5>
          </div>
        </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img width="100%" height="100%" title="Danny's Bar & Grill" className="d-block w-100" src={Slider4} alt="Danny's Bar & Grill" loading="lazy"/>
          <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
          <h1>Welcome to Danny's Bar & Grill</h1>
          <h5>What do you have planned this weekend? We hope we're included.</h5>
          </div>
        </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img width="100%" height="100%" title="Danny's Bar & Grill" className="d-block w-100" src={Slider5} alt="Danny's Bar & Grill" loading="lazy"/>
          <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
          <h1>Welcome to Danny's Bar & Grill</h1>
          <h5>Effingham has been loving us since the year 2000! You would have to step through our doors to feel the hype.</h5>
          </div>
        </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};
