import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function PhotoGalleryList() {
  return (
    <ImageList
      // sx={{ width: 500, height: 450 }}
      wrapperClass="wrapper"
      gridClass="col-md-4"
      variant="quilted"
      imgClass="responsive"
      cols={4}
      // rowHeight={150}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
          <img
            {...srcset(item.img, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
            title={item.title}
            width="100%"
            height="100%"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: '/static/1.jpg',
    title: 'Dannys Bar & Grill Image001',
  },
  {
    img: '/static/2.jpg',
    title: 'Dannys Bar & Grill Image002',
  },
  {
    img: '/static/3.jpg',
    title: 'Dannys Bar & Grill Image003',
  },
  {
    img: '/static/4.jpg',
    title: 'Dannys Bar & Grill Image004',
  },
  {
    img: '/static/5.jpg',
    title: 'Dannys Bar & Grill Image005',
  },
  {
    img: '/static/6.jpg',
    title: 'Dannys Bar & Grill Image006',
    author: '@arwinneil',
  },
  {
    img: '/static/7.jpg',
    title: 'Dannys Bar & Grill Image007',
  },
  {
    img: '/static/8.jpg',
    title: 'Dannys Bar & Grill Image008',
  },
  {
    img: '/static/9.jpg',
    title: 'Dannys Bar & Grill Image009',
  },
  {
    img: '/static/10.jpg',
    title: 'Dannys Bar & Grill Image010',
  },
  {
    img: '/static/11.jpg',
    title: 'Dannys Bar & Grill Image011',
  },
  {
    img: '/static/12.jpg',
    title: 'Dannys Bar & Grill Image012',
  },
  {
    img: '/static/13.jpg',
    title: 'Dannys Bar & Grill Image013',
  },
  {
    img: '/static/14.jpg',
    title: 'Dannys Bar & Grill Image014',
  },
  {
    img: '/static/15.jpg',
    title: 'Dannys Bar & Grill Image015',
  },
  {
    img: '/static/16.jpg',
    title: 'Dannys Bar & Grill Image016',
  },  
  {
    img: '/static/17.jpg',
    title: 'Dannys Bar & Grill Image017',
  },  
  {
    img: '/static/18.jpg',
    title: 'Dannys Bar & Grill Image018',
  },  
  {
    img: '/static/19.jpg',
    title: 'Dannys Bar & Grill Image019',
  },  
  {
    img: '/static/20.jpg',
    title: 'Dannys Bar & Grill Image020',
  },                
];
