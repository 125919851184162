import showcaseimg from "../../../../../assets/images/about-img-left.png";
import showcaseimg1 from "../../../../../assets/images/showcase-icon.png";
import "../../../../../App.css";
// import whitespace from "../../../../../assets/css/custom.css";
import { Container, Row, Col, Button, h1, h2, p, h3, hr } from "react-bootstrap";

export const ShowCase = () => {
  return (
    <Container className="showcase">
      <Row>
        <Col xs={6} md={6}>
          <img width="100%" height="100%" title="Danny's Bar & Grill Showcase Image Left" className="img-sm" src={showcaseimg} alt="Danny's Bar & Grill Showcase Image Left" loading="lazy"/>
        </Col>
        <Col xs={6} md={5}>
          <div className="left margin-top-20">
            <h1>
            Effingham has been loving us since the year 2000!
            </h1>
            <h2 className="margin-top-20">Get to know us</h2>
          </div>
          <div className="left margin-top-20">
            <p>
              {" "}
              With 23 years of fun here in Effingham, the community we've built has been no surprise to us. Let's keep the good times rolling!
            </p>
          </div>
          <div className="showcase-right-text left">
            <Col xs={6} md={3} className="img-icon">
              <img width="100%" height="100%" title="Danny's Bar & Grill Showcase Icon"  src={showcaseimg1} size="25" alt="Danny's Bar & Grill Showcase Icon" loading="lazy"/>
            </Col>
            <p>
              <h3> Discover Menu </h3>
              <span>Searching for delicious, local eats in Effingham? We have good news -- you'll find them here!</span>
              <br></br>
              <span><Button
                className="btn-red margin-top-10 margin-bottom-10"
                href="/menu"
              >
                Order Now{" "}
              </Button>
              </span>
              </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};