import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

export default function BusinessCard() {
  return (
    <Card>
      <CardActionArea
        className="color-scheme"
        sx={{ cursor: "default !important", pointerEvents: "none" }}
      >
        <CardMedia
          component="img"
          height="140"
          image="/static/business-hours.png"
          alt="Business Hours"
          width="100%"
          loading="lazy"
          title="Danny's Bar & Grill Business Hours"
        />
        <CardContent>
          <Typography gutterBottom variant="h4" component="div">
            Business Hours
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            <span>Monday - Saturday: 8:00 AM - 1:00 AM (Next day)</span>
            <br></br>
            <span>Sunday: 10:00 AM - 1:00 AM (Next day)</span>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
